import React from 'react';
import styled from 'styled-components';

import Page from '../components/core/page';
import Hero from '../components/pages/home/hero';
import Opener from '../components/common/opener';
import LogoSection from '../components/pages/home/logo-section';
import ServiceCard from '../components/pages/services/service-card';
import Form from '../components/pages/contact/form';
import Details from '../components/pages/contact/details';
import Downtown from '../components/pages/contact/downtown';

import brandingImage from '../images/services/branding.jpg';
import graphicDesignImage from '../images/services/graphic-design.jpg';
import websitesImage from '../images/services/websites.jpg';
import ecommImage from '../images/services/ecomm.jpg';
import advertisingImage from '../images/services/ads.jpg';

import hand from '../images/hand.svg';

const DowntownSection = styled.section`
	padding-top: 4rem;
	background-color: ${(p) => p.theme.color.ghost};
	padding-bottom: 1rem;
`;

const ServiceSection = styled.section`
	padding-bottom: 4rem;
`;

const OpenerSection = styled.section`
	@media ${(p) => p.theme.device.tablet} {
		.hand {
			display: none;
		}
	}
`;

const FormHeading = styled.section`
	background-color: ${(p) => p.theme.color.primary};
	text-align: center;
	padding: 4rem 1rem 0 1rem;
	h3 {
		font-size: 2rem;
		max-width: 750px;
		margin: 0 auto;
		line-height: 1.5;
	}
`;

export default () => (
	<Page
		title="Landing"
		desc="Established by 5 marketing & ad agency veterans, 5fold elevates creativity, technology and customer service to help each of our clients become a success story."
	>
		<Hero>
			<span>A creative agency for </span>
			<span>businesses and brands who </span>
			<span>
				<strong>strive to thrive</strong>
			</span>
		</Hero>
		<OpenerSection>
			<Opener title="about us" subtitle="SMALL TOWN. WORLD CLASS.">
				<div>
					<h2>
						Elevating creativity, technology and customer service to help each
						of our clients become a success story.
					</h2>
					<p>
						Established by five marketing and advertising agency veterans with a
						track record for serving both local businesses and national brands,
						5fold is a creative agency relentlessly driven to help our clients
						reach their goals and their full potential.
					</p>
				</div>
				<div className="hand">
					<img src={hand} alt="sketch hand" />
				</div>
			</Opener>
		</OpenerSection>
		<ServiceSection>
			<ServiceCard
				image={brandingImage}
				title="BRANDING"
				paragraph="Your brand’s success should be by design. Whether it’s starting a new identity from scratch or refreshing a tired one, we work collaboratively with our clients to help bring their vision to life."
				list={[
					'LOGO DESIGN',
					'NAMING & KEY MESSAGING',
					'IDENTITY SYSTEMS',
					'BRAND COLLATERAL',
					'BRAND GUIDELINES',
					'BRANDED CONTENT',
				]}
				isReverse={false}
			/>
			<ServiceCard
				image={graphicDesignImage}
				title="GRAPHIC DESIGN"
				paragraph="If it involves pencils or pixels, we’ve got you covered. Our team of designers, illustrators and art directors specialize in making your message impossible to ignore and beautiful to behold."
				list={[
					'CREATIVE SERVICES',
					'ILLUSTRATION',
					'ART PRODUCTION',
					'PACKAGING',
					'BUSINESS COLLATERAL',
					'PHOTOGRAPHY',
				]}
				isReverse={true}
			/>
			<ServiceCard
				image={websitesImage}
				title="WEBSITES"
				paragraph="Shake off your website shame; we know all the tricks to make customers click. Big or small, building beautiful, conversion-focused user experiences is our domain."
				list={[
					'RESEARCH & STRATEGY',
					'CONTENT MANAGEMENT',
					'DESIGN & DEVELOPMENT',
					'CUSTOM INTEGRATIONS',
					'REPLATFORMING',
					'HOSTING & MAINTENANCE',
				]}
				isReverse={false}
			/>
			<ServiceCard
				image={ecommImage}
				title="E-COMMERCE"
				paragraph="We’re perfecting the art of ADD TO CART. 
			We provide comprehensive e-commerce strategies combined with world-class UX & UI design to help our clients sell and scale."
				list={[
					'SHOPIFY PARTNERS',
					'RETAIL INTEGRATIONS',
					'FULFILLMENT SOLUTIONS',
					'SEO & UX STRATEGY',
					'PAYMENT PROCESSING',
					'CUSTOMIZED DESIGN',
				]}
				isReverse={true}
			/>
			<ServiceCard
				image={advertisingImage}
				title="ADVERTISING"
				paragraph="You can’t sell it if you don’t tell it. You need to let the world know you exist, and we’re experts in crafting disruptive and compelling campaigns that connect brands with audiences."
				list={[
					'INTEGRATED CAMPAIGNS',
					'DIGITAL ADVERTISING',
					'CREATIVE / ART DIRECTION',
					'PAID MEDIA',
					'COPYWRITING',
					'GOOGLE ADS',
				]}
				isReverse={false}
				partnerBadge={true}
			/>
		</ServiceSection>
		<LogoSection />
		<FormHeading>
			<h3>
				Ready to start a new project? We'd love to help. Fill out the form below
				and we'll be in touch.
			</h3>
		</FormHeading>
		<Form />
		<Details />
		<DowntownSection>
			<Downtown />
		</DowntownSection>
	</Page>
);
